import { ApiClientProps } from "hooks/useApi";

export interface NewLightspeedWebhookUrl {
  locationId: string;
  partnerKey: string;
  apiType?: string;
  enabled: boolean;
  comment?: string;
}

export interface LightspeedWebhookUrl {
  locationId: string;
  partnerKey: string;
  apiType?: string;
  enabled: boolean;
  comment?: string;
}

export interface LightspeedWebhookUrlResponse {
  hasErrors: false;
  message: string;
  resource: string;
}

async function createLightspeedWebhookUrl(
  {
    locationId,
    partnerKey,
    apiType,
    enabled,
    comment,
  }: NewLightspeedWebhookUrl,
  apiClient: ApiClientProps,
  partnerId?: string,
  token?: string
): Promise<LightspeedWebhookUrlResponse> {
  if (!locationId || !partnerKey || !apiType) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for creating the location");
  }

  if (!partnerId) {
    throw new Error("No partnerId provided for creating the location");
  }

  const body = JSON.stringify({
    locationId,
    partnerKey,
    apiType,
    enabled,
    comment
  });

  const response =  await apiClient.post({
    path: `/middleware-admin/lightspeed/${partnerId}/webhook-urls`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body,
  });

  return response;
}

export default createLightspeedWebhookUrl;

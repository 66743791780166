import * as Styled from "./AddLocationForm.styles";

import { Collapse, Form, FormInstance, Input, Select } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useRef, useState } from "react";

import { useLocation } from "context/LocationContext";
import { usePartner } from "context/PartnerContext";

const { Panel } = Collapse;

function AddLocationForm() {
  const [expanded, setExpanded] = useState(false);
  const [locationId, setLocationId] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [posType, setPosType] = useState<string>("toast");
  const [apiType, setApiType] = useState<string | undefined>();
  const [partnerId, setPartnerId] = useState<string | undefined>("1");
  const [partnerKey, setPartnerKey] = useState<string | undefined>();
  const [upserveUsername, setUpserveUsername] = useState<string | undefined>();
  const [upservePassword, setUpservePassword] = useState<string | undefined>();

  const formRef = useRef<FormInstance | null>(null);

  const { locationActions } = useLocation();
  const { partners, currentPartner } = usePartner();

  const isDisabled =
    posType === "upserve"
      ? !upserveUsername || !upservePassword || !locationId || !name
      : !locationId || !name;

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerKey(partners?.at(0)?.key ?? "resy-prod-sample");
    }
  }, [partners]);

  useEffect(() => {
    if (currentPartner) {
      setPartnerKey(currentPartner.key);
      setPartnerId(currentPartner.id.toString());
    }
  }, [currentPartner]);

  const handleOnChange = useCallback((key: string | string[]) => {
    setExpanded(key.length > 0);
  }, []);

  const CollapseIcon = () => {
    return expanded ? <MinusOutlined /> : <PlusOutlined />;
  };

  const handleLocationId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
      setLocationId(event?.currentTarget.value);
    },
    []
  );

  const handleLocationName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
      setName(event?.currentTarget.value);
    },
    []
  );

  const handleLocationUpserveUsername = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
      setUpserveUsername(event?.currentTarget.value);
    },
    []
  );

  const handleLocationUpservePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
      setUpservePassword(event?.currentTarget.value);
    },
    []
  );

  const handlePosType = useCallback((value: string) => {
    setPosType(value);
    setUpserveUsername(undefined);
    setUpservePassword(undefined);
  }, []);

  const handleApiType = useCallback((value: string) => {
    setApiType(value);
  }, []);

  const handleAddLocation = useCallback(() => {
    if (!locationId || !name || !posType || !partnerId || !partnerKey) {
      return console.error("Incomplete params to add the new location");
    }

    locationActions.create({
      locationId,
      name,
      posType,
      apiType,
      partnerId,
      partnerKey,
      upserveUsername,
      upservePassword,
    });
  }, [
    locationId,
    name,
    posType,
    apiType,
    locationActions,
    partnerId,
    partnerKey,
    upserveUsername,
    upservePassword,
  ]);

  return (
    <Styled.Container>
      <Styled.Collapsible
        expandIcon={() => <CollapseIcon />}
        expandIconPosition="end"
        onChange={handleOnChange}
      >
        <Panel header="Add new location" key={1}>
          <Form layout="vertical" ref={formRef}>
            <Styled.AddFormItem
              label="LocationId Number"
              required
              tooltip="A LocationId number is required"
            >
              <Input
                disabled={locationActions.isCreateLoading}
                onChange={(event) => {
                  void handleLocationId(event);
                }}
                placeholder="Enter LocationId Number"
              />
            </Styled.AddFormItem>
            <Styled.AddFormItem
              label="Location Name"
              required
              tooltip="Set a name to save the location"
            >
              <Input
                disabled={locationActions.isCreateLoading}
                onChange={(event) => {
                  void handleLocationName(event);
                }}
                placeholder="Enter Location Name"
              />
            </Styled.AddFormItem>
            <Styled.AddFormItem
              label="POS System"
              required
              tooltip="Set a POS System"
            >
              <Select
                defaultValue="toast"
                disabled={locationActions.isCreateLoading}
                onChange={(value: string) => {
                  void handlePosType(value);
                }}
                options={[
                  { value: "omnivore", label: "Omnivore" },
                  { value: "toast", label: "Toast" },
                  { value: "upserve", label: "Upserve" },
                  { value: "square", label: "Square" },
                  { value: "lightspeed", label: "Lightspeed" },
                ]}
                placeholder="Select a POS System"
                data-testid="PosSystemId"
              />
            </Styled.AddFormItem>
            {/* <Styled.AddFormItem
              label="Partner Key"
              required
              tooltip="Set Partner Key"
            >
              <Select
                defaultValue={partners?.at(0)?.name}
                disabled={locationActions.isCreateLoading}
                onChange={(value: string) => {
                  void handlePartner(value);
                }}
                options={partners?.map((partner) => {
                  return {
                    value: partner.key,
                    label: partner.name,
                  };
                })}
                placeholder="Select a Partner Key"
                data-testid="PartnerKeyId"
              />
            </Styled.AddFormItem> */}

            {posType === "upserve" && (
              <>
                <Styled.AddFormItem
                  label="Upserve Username"
                  required
                  tooltip="Set a Upserve Username to save the location"
                >
                  <Input
                    disabled={locationActions.isCreateLoading}
                    onChange={(event) => {
                      void handleLocationUpserveUsername(event);
                    }}
                    placeholder="Enter Upserve Location Username"
                  />
                </Styled.AddFormItem>
                <Styled.AddFormItem
                  label="Upserve Password"
                  required
                  tooltip="Set a Upserve Password to save the location"
                >
                  <Input.Password
                    disabled={locationActions.isCreateLoading}
                    onChange={(event) => {
                      void handleLocationUpservePassword(event);
                    }}
                    placeholder="Enter Upserve Location Password"
                  />
                </Styled.AddFormItem>
              </>
            )}

            {posType === "lightspeed" && (
              <>
                <Styled.AddFormItem
                  label="Api Type"
                  required
                  tooltip="Set a Lightspeed Api Type to create location"
                >
                  <Select
                    disabled={locationActions.isCreateLoading}
                    onChange={(value: string) => {
                      void handleApiType(value);
                    }}
                    options={[
                      { value: "lightspeed-api-l-series", label: "L-Series" },
                    ]}
                    placeholder="Select a Api Type"
                  />
                </Styled.AddFormItem>
              </>
            )}

            <Styled.AddFormItem>
              <Styled.AddButton
                disabled={isDisabled}
                loading={locationActions.isCreateLoading}
                type="default"
                onClick={handleAddLocation}
                className="overwrite"
              >
                Add Location
              </Styled.AddButton>
            </Styled.AddFormItem>
          </Form>
        </Panel>
      </Styled.Collapsible>
    </Styled.Container>
  );
}

export default AddLocationForm;
